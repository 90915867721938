import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addPractice,
  fetchPractices,
  removePractice,
  fetchSubmissions,
} from "../../../actions";

import Layout from "../../../components/layout";
import Container from "../../../common/components/container";

import queryString from "query-string";
import { Location } from "@reach/router";
import SubmissionsList2 from "./submissionsList2";

import analytics from "../../../collections/analytics/analytics.css";

const Index = () => {
  const dispatch = useDispatch();
  const [practice_name, update_practice_name] = useState("");

  const submissions = useSelector((state) => state.submissions);

  useEffect(() => {
    dispatch(fetchPractices());
  }, []);

  function handleAddPractice() {
    dispatch(addPractice(practice_name));
    update_practice_name("");
  }

  function handleRemovePractice(practice) {
    dispatch(removePractice(practice));
  }

  const table = {
    header_array: ["Name", "ID", "Created", "Remove?"],
    content: submissions,
    handleRemoveEntry: handleRemovePractice,
  };

  function returnForm(location, navigate) {
    return (
      <SubmissionsList2
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    );
  }

  return (
    <Layout>
      <Container>
        <Location>
          {({ location, navigate }) => returnForm(location, navigate)}
        </Location>
      </Container>
    </Layout>
  );
};

export default Index;
